body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/**
 * Manually override MUI dialog.
 *
 * This is needed for the delete confirm only, but there is no way to specifically target that
 * without copy/praysting two complex components.
 */
body
  > div.MuiDialog-root
  > div.MuiDialog-container.MuiDialog-scrollPaper
  > div {
  background-color: #274c68;
}
body > div.MuiDialog-root > div.MuiDialog-container > div,
body > div.MuiDialog-root > div.MuiDialog-container > div p {
  color: white;
}
body
  > div.MuiDialog-root
  > div.MuiDialog-container.MuiDialog-scrollPaper
  button
  > span.MuiButton-label {
  color: #ffdd13;
}
body
  > div.MuiDialog-root
  > div.MuiDialog-container.MuiDialog-scrollPaper
  button
  > span.MuiButton-label
  > svg {
  display: none;
}
